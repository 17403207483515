import {NavLink} from "react-router-dom";
import {ArrowRightAlt} from "@mui/icons-material";
import "./home-layout3.scss";

export function HomeLayout3({instance}) {
    return <div id="homeLayout3">
        <div className="cover-photo">
            <img alt={instance.coverPhoto?.name} src={instance.coverPhoto?.url}/>
            <div className="instance-details">
                <div className="box">
                    <h1>{instance.title}</h1>
                    <h2>{instance.subtitle}</h2>
                    <NavLink to="/portfolio" className="goto-website-link">
                        <span>ENTER</span><ArrowRightAlt/>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>;
}