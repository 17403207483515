import React, {useEffect, useState} from "react";
import usePublicApi from "../../public/services/public-api";

export const CurrentInstanceContext = React.createContext(
    undefined
);

export const CurrentInstanceProvider = ({children}) => {
    const {fetchAlbums} = usePublicApi();

    const [initialized, setInitialized] = useState(false);
    const [instance, setInstance] = useState(null);
    const [albums, setAlbums] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(-1);

    useEffect(() => {
        void (async () => {
            if (fetchAlbums && !initialized) {
                setInitialized(true);
                try {
                    setApiError(null);
                    const {instance, albums} = await fetchAlbums();
                    document.title = `${instance.title} | ${instance.subtitle}`;
                    setAlbums(albums);
                    setInstance(instance);
                    setIsLoading(false);
                } catch (err) {
                    console.error("Could not fetch albums", err);
                    setApiError("An error occurred trying to fetch your albums, please contact the system administrator");
                    setIsLoading(false);
                    setAlbums([]);
                    setInstance(null);
                }
            }
        })();
    }, [fetchAlbums, initialized]);

    const value = {
        instance,
        setInstance,
        albums,
        setAlbums,
        isLoading,
        setIsLoading,
        apiError,
        setApiError,
        selectedAlbum,
        setSelectedAlbum,
        selectedPhotoIndex,
        setSelectedPhotoIndex
    };

    return <CurrentInstanceContext.Provider value={value}>{children}</CurrentInstanceContext.Provider>;
};