import {Photo} from "./photo";

export class Instance {
    constructor(instance) {
        this._id = instance?._id;
        this.instanceId = instance?.instanceId;
        this.title = instance?.title;
        this.subtitle = instance?.subtitle;
        this.coverPhoto = instance?.coverPhoto ? new Photo(instance.coverPhoto) : null;
        this.homeLayout = instance?.homeLayout;
    }
}