import * as React from "react";

import "./public.scss"
import {useLayoutEffect, useState} from "react";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {KeyboardArrowRightRounded, Menu} from "@mui/icons-material";
import {PageTransitionContext} from "./context/pageTransition";
import {PhotoViewer} from "../components/photo-viewer/photo-viewer";
import Loader from "../components/Loader";
import {useCurrentInstance} from "../services/current-instance/use-current-instance";

export default function PublicLayout() {
    const {isLoading, apiError, albums} = useCurrentInstance();

    const [mounted, setMounted] = useState(false);
    const pageTransition = {
        mounted, setMounted
    }
    // const fetchedRef = useRef(false);
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const location = useLocation();
    useLayoutEffect(() => {
        setMounted(false);
        setTimeout(() => {
            setMounted(true);
        }, 10);
    }, [location.pathname]);

    return <PageTransitionContext.Provider value={pageTransition}>
            <PhotoViewer />
            <div id="publicPage">
                <main id="page">
                    <Loader loading={isLoading} />
                    {!isLoading && <>
                        <div id="sidebarButton" onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                            <Menu />
                        </div>
                        <div id="sidebar" className={`${(sidebarIsOpen ? "sidebar-open" : "sidebar-closed")}`}>
                            <div className="sidebar">
                                <NavLink onClick={() => setSidebarIsOpen(false)} to="/" className="home">Home</NavLink>
                                {albums.map(a => <NavLink onClick={() => setSidebarIsOpen(false)} key={a.albumId} to={a.albumId}><KeyboardArrowRightRounded /> {a.name}</NavLink>)}
                            </div>
                        </div>
                        <div id="sidebar-mobile" className={`sidebar ${(sidebarIsOpen ? "sidebar-open" : "sidebar-closed")}`}>
                            <NavLink onClick={() => setSidebarIsOpen(false)} to="/" className="home">Home</NavLink>
                            {albums.map(a => <NavLink onClick={() => setSidebarIsOpen(false)} key={a.albumId} to={a.albumId}><KeyboardArrowRightRounded /> {a.name}</NavLink>)}
                        </div>
                        <div id={"page-content"} className={`${(sidebarIsOpen ? "sidebar-open" : "sidebar-closed")} ${(pageTransition.mounted ? "mounted" : "unmounted")}`}>
                            {apiError && <div className="error-box">{apiError}</div>}
                            {!apiError && <Outlet />}
                        </div>
                    </>}
                </main>
            </div>
    </PageTransitionContext.Provider>
}