import {useCurrentInstance} from "../../services/current-instance/use-current-instance";

import {HomeLayout1} from "../../components/home-layouts/home-layout1";
import {HomeLayout2} from "../../components/home-layouts/home-layout2";
import {HomeLayout3} from "../../components/home-layouts/home-layout3";

export function HomePage() {
    const {instance} = useCurrentInstance();
    return instance && (instance.homeLayout === 3
        ? <HomeLayout3 instance={instance}/>
        : instance.homeLayout === 2
            ? <HomeLayout2 instance={instance}/>
            : <HomeLayout1 instance={instance}/>)
        ;
}