import {CurrentInstanceContext} from "./current-instance-context";
import {useContext} from "react";

/**
 * @return {InstancesContext}
 */
export const useCurrentInstance = () => {
    const context = useContext(CurrentInstanceContext);

    if (!context) {
        throw new Error(
            'Current instance context is undefined, please verify you are calling useCurrentInstance() as child of a <CurrentInstanceProvider> component.',
        );
    }

    return context;
};