import {Album} from "../../models/album";
import {Instance} from "../../models/instance";

const apiUrl = window._env_.REACT_APP_API_URL;

export default function usePublicApi() {

    return {
        fetchAlbums
    };

    /**
     * @return {Promise<{instance: Instance, albums: Album[]}>}
     */
    async function fetchAlbums() {
        const response = await handleResponse(await fetch(`${apiUrl}/api/albums`));
        return {
            instance: new Instance(response.data.instance),
            albums: response.data.albums.map(album => new Album(album))
        };
    }

    async function handleResponse(response) {
        const text = await response.text()

        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    }
}