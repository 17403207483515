import * as React from "react";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {ZoomOutMapSharp} from "@mui/icons-material";
import {useCurrentInstance} from "../../services/current-instance/use-current-instance";

export default function AlbumPage() {
    const {
        albums,
        selectedAlbum,
        setSelectedAlbum,
        setSelectedPhotoIndex
    } = useCurrentInstance();
    const {albumId} = useParams();

    useEffect(() => {
        const ab = albums.filter(a => a.albumId === albumId)[0];
        if (selectedAlbum !== ab) {
            setSelectedAlbum(ab);
        }
    }, [albums, selectedAlbum, albumId, setSelectedAlbum]);

    return selectedAlbum && <div id="page-album" className="page-outlet">
        <div className="page-title">
            <div className="title">
                {selectedAlbum.name}
            </div>
            {selectedAlbum.description &&
                <div className="extra-info"
                     dangerouslySetInnerHTML={{__html: selectedAlbum.description.replace(/\n/g, "<br />")}}>
                </div>}
        </div>
        {selectedAlbum.photos && <div id="photo-list" className="photo-grid-layout photo-grid-layout--no-details">
            {selectedAlbum.photos.map((photo, idx) =>
                <div className="photo grid-cell" key={photo.photoId}
                     onClick={() => setSelectedPhotoIndex(idx)}>
                    <div className="thumbnail photo-thumbnail">
                        <img src={`${photo.url}?width=800&height=800&fit=cover`}
                             alt={photo.name}/>
                        <div className="zoom-button">
                            <ZoomOutMapSharp/>
                        </div>
                    </div>
                    {/*<div className="details">*/}
                    {/*    <div className="name">*/}
                    {/*        {photo.name}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>}
    </div>
}
