import {NavLink} from "react-router-dom";
import {ArrowRightAlt} from "@mui/icons-material";
import "./home-layout2.scss";

export function HomeLayout2({instance}) {
    return <div id="homeLayout2">
        <div className="instance-details">
            <div className="box">
                <h1>{instance.title}</h1>
                <h2>{instance.subtitle}</h2>
                <NavLink to="/portfolio" className="goto-website-link">
                    <span>ENTER</span><ArrowRightAlt/>
                </NavLink>
            </div>
        </div>
        <div className="cover-photo">
            <img alt={instance.coverPhoto?.name} src={instance.coverPhoto?.url}/>
        </div>
    </div>;
}