import React, {useLayoutEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PublicLayout from "./public/layout";
import Portfolio from "./public/pages/portfolio";
import AlbumPage from "./public/pages/album";
import AccessDenied from "./public/pages/access-denied";
import {HomePage} from "./public/pages/home-page";
import {CurrentInstanceProvider} from "./services/current-instance/current-instance-context";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CurrentInstanceProvider>
                <ScrollToTop>
                    <Routes>
                        <Route index element={<HomePage/>}/>
                        <Route path="/portfolio" element={<PublicLayout/>}>
                            <Route index element={<Portfolio/>}/>
                            <Route path=":albumId" element={<AlbumPage/>}/>
                            <Route path="401" element={<AccessDenied/>}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Route>
                    </Routes>
                </ScrollToTop>
            </CurrentInstanceProvider>
        </BrowserRouter>
    </React.StrictMode>
);

function ScrollToTop({children}) {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}
