import {createAnimation} from "../utils/animation";

const sync = createAnimation(
    "SyncLoader",
    `33% {transform: translateY(10px)}
  66% {transform: translateY(-10px)}
  100% {transform: translateY(0)}`,
    "sync"
);

function Loader({
                    loading = false,
                    color = "#000000",
                    speedMultiplier = 1,
                    cssOverride = {},
                    size = 15,
                    margin = 2,
                    ...additionalprops
                }) {
    const wrapper = {
        display: "inherit",
        ...cssOverride,
    };

    const style = (i) => {
        return {
            backgroundColor: color,
            width: size,
            height: size,
            margin: margin,
            borderRadius: "100%",
            display: "inline-block",
            animation: `${sync} ${0.6 / speedMultiplier}s ${i * 0.07}s infinite ease-in-out`,
            animationFillMode: "both",
        };
    };

    if (!loading) {
        return null;
    }

    return (
        <div id="page-loader">
            <span style={wrapper} {...additionalprops}>
                <span style={style(1)}/>
                <span style={style(2)}/>
                <span style={style(3)}/>
            </span>
        </div>
    );
}

export default Loader;